import Icon from 'components/atoms/Icon';
import { useNavigate } from 'react-router-dom';
import { Button } from '@spotted-zebra-uk/ui-components';
import AddTechnicalSkillsForm from './AddTechnicalSkills.form';
import styles from './styles.module.scss';

const AddTechnicalSkillsView = () => {
  const navigate = useNavigate();
  return (
    <div className={styles.container}>
      <div className={styles.subpageContainer}>
        <div className={styles.subpageHeader}>
          <Button
            className={styles.subpageBackNavigationLink}
            variant="text"
            size="small"
            leftIcon={<Icon icon="left_arrow" />}
            onClick={() => {
              navigate(-1);
            }}
          >
            Technical Skills
          </Button>
        </div>

        <div className={styles.subpageBody}>
          <h2 className={styles.subpageTitle}>Add skill</h2>
          <AddTechnicalSkillsForm />
        </div>
      </div>
    </div>
  );
};

export default AddTechnicalSkillsView;
