import TextWithTooltip from 'components/ui/TextWithTooltip/TextWithTooltip';
import {
  SortOption,
  TechnicalSkillFragment,
  TechnicalSkillSortField,
} from 'generated/graphql';
import { formatProjectDate } from 'helpers/project';

export type Pagination = {
  page: number;
  pageSize: number;
};

export type TOrder =
  | {
      field: TechnicalSkillSortField;
      direction: SortOption;
    }
  | undefined;

export type TechnicalSkillFilters = {
  skillCategoryNames: string[];
};

export const DEFAULT_TABLE_PAGE_SIZE = 20;

export const tableHeaders = [
  {
    key: TechnicalSkillSortField.Name,
    header: 'Name',
  },
  {
    key: TechnicalSkillSortField.SkillCategoryName,
    header: 'Category',
  },
  {
    key: TechnicalSkillSortField.CreatedAtDate,
    header: 'Created',
  },
  {
    key: TechnicalSkillSortField.UpdatedAtDate,
    header: 'Last edited',
  },
];

export function parseTableData(technicalSkill: TechnicalSkillFragment) {
  return {
    id: technicalSkill.id.toString(),
    [TechnicalSkillSortField.Name]: technicalSkill.name,
    [TechnicalSkillSortField.SkillCategoryName]: (
      <TextWithTooltip text={technicalSkill.skillCategory?.name || ''} />
    ),
    [TechnicalSkillSortField.CreatedAtDate]: formatProjectDate(
      technicalSkill.createdAt
    ),
    [TechnicalSkillSortField.UpdatedAtDate]: formatProjectDate(
      technicalSkill.updatedAt
    ),
  };
}

export const getSelectOption = (category: string) => {
  return {
    label: category,
    value: category,
  };
};
