import Button from 'components/atoms/Button/Button';
import CompanySelectFormField from 'components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import ProjectStagesSelectFormField from 'components/molecules/SelectFormField/ProjectStagesSelectFormField/ProjectStagesSelectFormField';
import { useStageCandidateCopyTestResultsMutation } from 'generated/graphql';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import { FC, useState } from 'react';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { ICopyCandidateReport, IStageValues } from '../../UserResets.interface';

const CopyCandidateReport: FC<ICopyCandidateReport> = ({
  userInfo,
  stageId,
}) => {
  const { handleMsgType } = useNotification();
  const [values, setValues] = useState<IStageValues>({
    stageId: 0,
    company: 0,
  });

  const handleChange = (value: TSelectFormFieldValue, name: string) => {
    setValues(prev => ({
      ...prev,
      [name]: value !== undefined ? value : '',
    }));
  };

  const [stageCandidateCopyTestResultsMutation] =
    useStageCandidateCopyTestResultsMutation({
      onError: error =>
        handleMsgType({
          type: TNotification.error,
          message: error?.message,
        }),
      onCompleted: () =>
        handleMsgType({
          type: TNotification.success,
          message: 'candidate report successfully copied',
        }),
    });

  const handleCopyCandidateReport = () => {
    stageCandidateCopyTestResultsMutation({
      variables: {
        candidateId: userInfo?.id || 0,
        baseStageId: stageId || 0,
        targetStageId: +values.stageId,
      },
    });
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '20px',
      }}
    >
      <p>Copy these results to a new project stage</p>
      <div style={{ width: 550, display: 'flex', justifyContent: 'flex-end' }}>
        <div style={{ width: 200 }}>
          <CompanySelectFormField
            id="company-id"
            name="company"
            label="Company"
            value={values.company}
            onChange={handleChange}
          />
        </div>
        <div style={{ marginLeft: 10, width: 200 }}>
          <ProjectStagesSelectFormField
            id="stage-id"
            name="stageId"
            label="Project - Stage"
            value={values.stageId}
            onChange={handleChange}
            companyId={+values.company}
          />
        </div>
        <div style={{ marginLeft: 10 }}>
          <Button
            isDisabled={!values.stageId}
            onClick={handleCopyCandidateReport}
          >
            Copy
          </Button>
        </div>
      </div>
    </div>
  );
};

export default CopyCandidateReport;
