import {
  CompanyAtsFindManyPaginatedQuery,
  useCompanyAtsFindManyPaginatedQuery,
} from 'generated/graphql';
import { useEffect, useState } from 'react';
import {
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';

export function useCompanyAts() {
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [searchValue, setSearchValue] = useState<string | undefined>('');
  const [companiesForSelect, setCompaniesForSelect] = useState<TSelectOption[]>(
    []
  );

  const { handleMsgType } = useNotification();

  const { loading, data } = useCompanyAtsFindManyPaginatedQuery({
    variables: {
      args: {
        search: searchValue,
      },
      paginate: {
        page: currentPage,
        size: 100,
      },
    },
    onCompleted: data => {
      const parsedData = parseDataForSelect(data);
      setCompaniesForSelect(_prev => [..._prev, ...parsedData]);
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${error.message}`,
      });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    setCompaniesForSelect([]);
    setCurrentPage(1);
  }, [searchValue]);

  const itemsTotal =
    data?.CompanyAtsInfoFindManyPaginated?.pageInfo?.itemsTotal || 0;

  const hasLoadMore = itemsTotal > companiesForSelect.length;

  return {
    selectOptions: companiesForSelect,
    loading,
    hasMoreItems: hasLoadMore,
    loadMore: () => setCurrentPage(_prev => _prev + 1),
    searchValue,
    setSearchValue,
  };
}

function parseDataForSelect(
  data: CompanyAtsFindManyPaginatedQuery | undefined
): TSelectOption[] {
  if (!data || !data.CompanyAtsInfoFindManyPaginated.data) {
    return [];
  }

  return data.CompanyAtsInfoFindManyPaginated.data?.map(atsLink => {
    if (atsLink.hasStackOneEnabled) {
      return {
        value: String(atsLink.id),
        label: atsLink.name,
        labelNote: String(atsLink.id),
        isDisabled: false,
      };
    }

    return {
      value: String(atsLink.id),
      label: atsLink.name,
      labelNote: String(atsLink.id),
      isDisabled: true,
      labelDescription: 'not integrated',
    };
  });
}
