import Form from 'components/feature/jobRole/JobRoleForm/JobRoleForm';
import {
  IJobRoleFormSubmitSoftSkillTraitValues,
  IJobRoleFormSubmitValues,
} from 'components/feature/jobRole/JobRoleForm/JobRoleForm.types';
import ProjectLink from 'components/feature/project/ProjectLink/ProjectLink';
import { getValueFromCompany } from 'components/molecules/SelectFormField/CompanySelectFormField/CompanySelectFormField';
import { appRoutes } from 'constants/navigation';
import {
  CalculationMethod,
  GradingMethod,
  JobRoleFamily,
  SuccessProfileDocument,
  SuccessProfileQuery,
  SuccessProfileQueryVariables,
  useCreateSuccessProfileMutation,
  useJobRoleCreateOneMutation,
  useProjectJobRoleCreateOneMutation,
  useSoftSkillFindManyQuery,
  useSuccessProfileSoftSkillsOverwriteMutation,
  useSuccessProfileTechnicalSkillsOverwriteMutation,
} from 'generated/graphql';
import { useGetProjectFromLocation } from 'helpers/jobRole';
import { FC, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ApolloError } from '@apollo/client';
import {
  Loader,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './CreateJobRole.module.scss';

const CreateJobRole: FC = () => {
  const { handleMsgType } = useNotification();
  const navigate = useNavigate();
  const [saveLoading, setSaveLoading] = useState(false);
  const { projectPath, projectQueryResponse } = useGetProjectFromLocation();

  const projectId = projectQueryResponse?.data?.project?.id;

  const softSkillsQueryResult = useSoftSkillFindManyQuery({
    variables: { isArchived: false },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: (error as ApolloError)?.message || 'Ops, some issue occurred!',
      });

      projectPath ? navigate(projectPath) : navigate(appRoutes.projects.url());
    },
  });

  const [createSuccessProfileMutation] = useCreateSuccessProfileMutation({
    update(cache, { data }) {
      const successProfile = data?.successProfile;
      if (successProfile && projectId) {
        cache.writeQuery<SuccessProfileQuery, SuccessProfileQueryVariables>({
          query: SuccessProfileDocument,
          variables: {
            projectId,
          },
          data: {
            SuccessProfile: successProfile,
          },
        });
      }
    },
    onError: error => {
      setSaveLoading(false);
      handleMsgType({
        type: TNotification.error,
        message:
          error?.message || 'Ops, some issue occurred while creating Job Role!',
      });
    },
  });

  const [updateSoftSkillsMutation] =
    useSuccessProfileSoftSkillsOverwriteMutation();

  const [updateTechnicalSkillsMutation] =
    useSuccessProfileTechnicalSkillsOverwriteMutation();

  const [jobRoleCreateOneMutation] = useJobRoleCreateOneMutation();

  const [projectJobRoleCreateOneMutation] =
    useProjectJobRoleCreateOneMutation();

  const createJobRole = (formValues: IJobRoleFormSubmitValues) => {
    return jobRoleCreateOneMutation({
      variables: {
        companyId: Number(formValues.company.value),
        name: formValues.jobRoleName,
        roleLevel: formValues.roleLevel,
        qualifier: formValues.additionalInformation,
        family: formValues.family.value as JobRoleFamily,
      },
    });
  };

  const createSkillsProfile = (
    jobRoleId: number,
    formValues: IJobRoleFormSubmitValues
  ) => {
    // TODO: Rename to createSkillsProfileMutation
    return createSuccessProfileMutation({
      variables: {
        roleLevel: formValues.roleLevel,
        calculationMethod: formValues.calculationMethod as CalculationMethod,
        gradingMethod: formValues.gradingMethod as GradingMethod,
        jobRoleId,
      },
    });
  };

  const parseTraitsFormValues = (
    traits: IJobRoleFormSubmitSoftSkillTraitValues[]
  ) => {
    return traits
      .filter(traitValues => traitValues.weight)
      .map(traitValues => ({
        traitId: traitValues.traitId,
        weight: traitValues.weight,
        orientation: traitValues.orientation,
      }));
  };

  const updateSoftSkills = (
    skillsProfileId: number,
    formValues: IJobRoleFormSubmitValues
  ) => {
    const parseSoftSkillValues = formValues.softSkills.map(softSkillValues => ({
      weight: softSkillValues.weight,
      softSkillId: softSkillValues.softSkillId,
      type: softSkillValues.type,
      dataSources: softSkillValues.dataSourceValues,
      includeInCalibration: softSkillValues.includeInCalibration,
      traits: parseTraitsFormValues(softSkillValues.traitsValues),
    }));

    return updateSoftSkillsMutation({
      variables: {
        id: skillsProfileId,
        softSkills: parseSoftSkillValues,
      },
    });
  };

  const updateTechnicalSkills = (
    skillsProfileId: number,
    formValues: IJobRoleFormSubmitValues
  ) => {
    updateTechnicalSkillsMutation({
      variables: {
        id: skillsProfileId,
        technicalSkills: formValues.technicalSkills.map(
          technicalSkillValue => ({
            technicalSkillId: Number(technicalSkillValue.value),
          })
        ),
      },
    });
  };

  const handleCreateJobRole = async (formValues: IJobRoleFormSubmitValues) => {
    setSaveLoading(true);

    // TODO: Remove this constraint after projectId is not required for creating success profile.
    if (!projectId) {
      setSaveLoading(false);
      return handleMsgType({
        type: TNotification.error,
        message: 'Ops, job role requires to be connected to the project!',
      });
    }

    try {
      const jobRoleResp = await createJobRole(formValues);

      const profileResp = await createSkillsProfile(
        jobRoleResp.data?.JobRoleCreateOne.id as number,
        formValues
      );

      if (formValues.softSkills.length) {
        await updateSoftSkills(
          profileResp.data?.successProfile.id as number,
          formValues
        );
      }

      if (formValues.technicalSkills.length) {
        await updateTechnicalSkills(
          profileResp.data?.successProfile.id as number,
          formValues
        );
      }
      // TODO: Add constraint here if (!projectId) {
      // projectJobRoleCreateOneMutation should be called only after user came from project page.
      await projectJobRoleCreateOneMutation({
        variables: {
          jobRoleId: jobRoleResp.data?.JobRoleCreateOne.id as number,
          projectId,
          skillsProfileId: profileResp.data?.successProfile.id as number,
        },
      });

      handleMsgType({
        type: TNotification.success,
        title: 'Job role created!',
      });
      if (projectPath) {
        navigate(projectPath);
      }
    } catch (error) {
      setSaveLoading(false);
      handleMsgType({
        type: TNotification.error,
        message:
          (error as ApolloError)?.message ||
          'Ops, some issue occurred while creating Job Role!',
      });
    }
  };

  const handleCancel = () => {
    if (projectPath) {
      navigate(projectPath);
    }
  };

  if (
    projectQueryResponse?.loading ||
    softSkillsQueryResult.loading ||
    saveLoading
  ) {
    return (
      <div className={styles.loaderWrapper}>
        <Loader variant="bubbles" />
      </div>
    );
  }

  if (softSkillsQueryResult.data) {
    const softSkills = softSkillsQueryResult.data.SoftSkillFindMany || [];
    const project = projectQueryResponse?.data?.project;
    const predefinedValues = {
      company: project?.company
        ? getValueFromCompany(project.company)
        : undefined,
    };

    return (
      <div
        className={styles.container}
        data-role="create-job-role-view"
        data-testid="create-job-role-view"
      >
        <div className={styles.nav}>
          {projectPath && project && (
            <ProjectLink project={project} projectPath={projectPath} />
          )}
        </div>
        <div className={styles.content}>
          <h1 className={styles.heading}>Create job role</h1>
          <Form
            softSkills={softSkills}
            onSubmit={handleCreateJobRole}
            onCancel={handleCancel}
            predefinedValues={predefinedValues}
          />
        </div>
      </div>
    );
  }

  return null;
};

export default CreateJobRole;
