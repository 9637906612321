import { MonolithBlobModel, WidgetOptionFieldType } from 'generated/graphql';
import { TWidgetOptions } from 'interfaces/widget';

export const formatUploadAssetsData = (widgetData: TWidgetOptions[]) => {
  return widgetData
    .filter(
      widget => widget.fieldType === WidgetOptionFieldType.FileUploadField
    )
    .map(widget => {
      if (!widget?.settings) {
        return { widgetId: widget.id || 0, id: null, url: null };
      }

      const blob = JSON.parse(widget.settings)?.blob as MonolithBlobModel;
      return { widgetId: widget.id || 0, id: blob?.id, url: blob?.url };
    });
};
