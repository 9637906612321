import '@spotted-zebra-uk/ui-components/dist/sz-theme/_globalStyles.scss';
import PrivateRoutes from 'components/molecules/PrivateRoutes/PrivateRoutes';
import history, { History } from 'config/history';
import { memo, useLayoutEffect, useState } from 'react';
import {
  BrowserRouterProps as NativeBrowserRouterProps,
  NavigationType,
  Route,
  Router,
  Routes,
} from 'react-router-dom';
import { ApolloProvider } from '@apollo/client';
import {
  createMuiTheme,
  Theme,
  ThemeProvider as MuiThemeProvider,
} from '@mui/material/styles';
import { ThemeProvider } from '@spotted-zebra-uk/ui';
import {
  useAccessibilityKeyboardDetection,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import { NavigationProvider } from '@teambit/base-react.navigation.link';
import { reactRouterAdapter } from '@teambit/ui-foundation.ui.navigation.react-router-adapter';
import AuthenticationWrapper from './components/organisms/AuthenticationWrapper/AuthenticationWrapper';
import client from './graphql/client';
import { Authentication } from './views/Authentication/Authentication';
import ModalView from './views/ModalView/ModalView';
import Root from './views/Root/Root';

export interface BrowserRouterProps
  extends Omit<NativeBrowserRouterProps, 'window'> {
  history: History;
}

export const BrowserRouter = memo(
  ({ history, ...rest }: BrowserRouterProps) => {
    const [state, setState] = useState({
      action: history.action,
      location: history.location,
    });

    useLayoutEffect(() => {
      history?.listen(({ location, action }) => {
        setState({ location, action });
      });
    }, [history]);

    return (
      <Router
        {...rest}
        navigator={history}
        location={state.location}
        navigationType={state.action as NavigationType}
      />
    );
  }
);

declare module '@mui/styles/defaultTheme' {
  interface DefaultTheme extends Theme {}
}

const theme = createMuiTheme();
function App() {
  const { notificationContainer } = useNotification();
  useAccessibilityKeyboardDetection();

  return (
    <>
      {notificationContainer()}
      <MuiThemeProvider theme={theme}>
        <ThemeProvider>
          <ApolloProvider client={client}>
            <AuthenticationWrapper>
              <BrowserRouter history={history}>
                <NavigationProvider implementation={reactRouterAdapter}>
                  <Routes>
                    <Route path="/auth/*" element={<Authentication />} />
                    <Route element={<PrivateRoutes />}>
                      <Route path="/*" element={<Root />} />
                    </Route>
                  </Routes>
                  <ModalView />
                </NavigationProvider>
              </BrowserRouter>
            </AuthenticationWrapper>
            <section id="sz-drawer"></section>
          </ApolloProvider>
        </ThemeProvider>
      </MuiThemeProvider>
    </>
  );
}

export default App;
