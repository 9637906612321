import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import { Form, Formik, useField } from 'formik';
import { uploadService } from 'helpers/uploadService';
import { useCompanyAts } from 'hooks/useCompanyAts';
import { ReactComponent as QuestionMarkIcon } from 'icons/questionmark.svg';
import { ReactComponent as ClockIcon } from 'icons/schedule.svg';
import { debounce } from 'lodash';
import { useState } from 'react';
import * as yup from 'yup';
import {
  Button,
  FormikSelectFormField,
  InformationalContent,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './AtsRequisitions.module.scss';
import { FormData } from './AtsRequisitions.types';

const validationSchema = yup.object().shape({
  company: yup.object().shape({
    value: yup.string().required('Required field'),
    label: yup.string().required('Required field'),
  }),
});

export function AtsRequisitions() {
  const { handleMsgType } = useNotification();
  const [isDownloading, setIsDownloading] = useState(false);

  const {
    selectOptions,
    loading,
    searchValue,
    setSearchValue,
    hasMoreItems,
    loadMore,
  } = useCompanyAts();

  async function handleSubmit(data?: FormData) {
    if (!data?.company?.value) {
      return;
    }

    setIsDownloading(true);

    const { error } = await uploadService.download(
      'stack-one/exports/jobs',
      'report.csv',
      {
        companyId: Number(data.company.value),
      }
    );

    setIsDownloading(false);

    if (!error) {
      handleMsgType({
        type: TNotification.success,
        title: 'Download started successfully',
      });
      return;
    }

    if (!error.error && !error.message) {
      handleMsgType({
        type: TNotification.error,
        title: 'Unable to start file download.',
        message: 'Something went wrong. Please try again.',
      });
      return;
    }

    if (error.error === 'Unauthorized') {
      handleMsgType({
        type: TNotification.error,
        title: 'No file available to download',
        message: `The company's ATS is not integrated with Spotted Zebra. If this is incorrect, please contact engineering.`,
      });
      return;
    }

    // GENERAL - Funnel BE errs to FE
    handleMsgType({
      type: TNotification.error,
      title: error.error,
      message: error.message,
    });
  }

  function handleInputChange(value: string) {
    if (!value && searchValue !== '') {
      setSearchValue('');
      return;
    }

    if (value) {
      setSearchValue(value);
      return;
    }
  }

  const debouncedInputChange = debounce(
    handleInputChange,
    DEBOUNCE_TIME_DEFAULT
  );

  return (
    <main className={styles.container}>
      <h1 className={styles.title}>ATS requisitions data</h1>

      <section className={styles.body}>
        <div className={styles.form}>
          <Formik<FormData>
            initialValues={{ company: undefined }}
            onSubmit={handleSubmit}
            validationSchema={validationSchema}
          >
            {({ values, resetForm }) => (
              <Form
                onSubmit={e => {
                  e.preventDefault();
                }}
              >
                <FormikSelectFormField
                  options={selectOptions}
                  id="company"
                  name="company"
                  label="Company"
                  placeholder="Company"
                  key="ats-integration__form-select"
                  useFormikField={useField}
                  onLoadMore={loadMore}
                  onInputChange={debouncedInputChange}
                  loadMore={hasMoreItems}
                  loadMoreButtonText="Load more"
                  isLoading={loading}
                />

                <div className={styles.btnWrapper}>
                  <Button
                    type="button"
                    loading={isDownloading}
                    disabled={isDownloading || !values.company?.value}
                    className={styles.submitBtn}
                    onClick={() => handleSubmit(values)}
                  >
                    Download export
                  </Button>

                  <Button
                    onClick={() => resetForm()}
                    variant="tertiary"
                    disabled={!values.company?.value}
                  >
                    Reset
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        </div>

        <div className={styles.info}>
          <InformationalContent
            orientation="vertical"
            items={[
              {
                title: 'How it works',
                content: `Download a CSV file of requisition data extracted through our integrations with clients' ATSs. The data is synced weekly and represents a snapshot of the most up-to-date information at the time of the sync.`,
                icon: <QuestionMarkIcon />,
              },
              {
                title: 'Timeframe',
                content: `Requisition data is extracted from the last 12 months. A job is considered relevant if it has been created, updated, or closed within that timeframe.`,
                icon: <ClockIcon />,
              },
            ]}
          ></InformationalContent>
        </div>
      </section>
    </main>
  );
}

export default AtsRequisitions;
