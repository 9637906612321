import { technicalSkillsRoutes } from 'constants/navigation';
import { Link } from 'react-router-dom';
import { Button } from '@spotted-zebra-uk/ui-components';
import styles from './styles.module.scss';

const TechnicalSkillsHeader = () => {
  return (
    <div className={styles.container}>
      <div className={styles.title}>Technical Skills</div>
      <div className={styles.menu}>
        <div>
          <Link to={technicalSkillsRoutes.addNew.url()}>
            <Button>Add skill</Button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default TechnicalSkillsHeader;
