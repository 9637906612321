import {
  useResultsPageProjectFindManyByCompanyQuery,
  useResultsPageStageFindManyQuery,
} from 'generated/graphql';
import { FC, useEffect, useMemo, useState } from 'react';
import {
  Checkbox,
  Multiselect,
  TMultiselectOption,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import styles from './ProjectStagesMultiSelectField.module.scss';

export interface IProjectStagesSelectFormField {
  id: string;
  name: string;
  label: string;
  onChange: (value: TMultiselectOption[], name: string) => void;
  value: TMultiselectOption[];
  isDisabled?: boolean;
  companyId: number;
  hasSelectAllOption?: boolean;
}

const ProjectStagesSelectFormField: FC<IProjectStagesSelectFormField> = ({
  id,
  name,
  label,
  onChange,
  value,
  isDisabled,
  companyId,
  hasSelectAllOption = false,
}) => {
  const { handleMsgType } = useNotification();
  const [selectAll, setSelectAll] = useState(false);
  const [isArchivedIncluded, setIsArchivedIncluded] = useState(false);

  const { data: projectsData } = useResultsPageProjectFindManyByCompanyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    variables: {
      companyId,
    },
    skip: !companyId,
  });

  const projectIds = useMemo(
    () => projectsData?.projects.map(project => project.id) || [],
    [projectsData]
  );

  const { data: stagesData } = useResultsPageStageFindManyQuery({
    skip: !projectIds.length,
    variables: {
      projectIds,
    },
  });

  const projectsSorted: TMultiselectOption[] = useMemo(() => {
    const stages =
      stagesData?.Stages?.map(stage => {
        const project = projectsData?.projects.find(
          (item: { id: number }) => item.id === stage.projectId
        );
        return {
          label: `${project?.name} (${project?.id})`,
          labelDescription: `${project?.isArchived ? 'Archived' : ''}`,
          value: ` ${stage.id}`,
          additionalProps: project?.isArchived,
        };
      }) || [];
    return stages
      .filter(stage => (isArchivedIncluded ? stage : !stage.additionalProps))
      .sort((a, b) => (a.label < b.label ? -1 : 1));
    // eslint-disable-next-line
  }, [stagesData, projectsData, isArchivedIncluded]);

  useEffect(() => {
    // Reset "Select all" on value change
    if (value.length < projectsSorted.length || value.length === 0) {
      setSelectAll(false);
    }
    // eslint-disable-next-line
  }, [value]);

  useEffect(() => {
    if (selectAll && isArchivedIncluded) {
      onChange(projectsSorted, name);
    }
    // eslint-disable-next-line
  }, [selectAll, isArchivedIncluded]);

  const handleSelectAll = () => {
    const newValue = !selectAll;
    onChange(newValue ? projectsSorted : [], name);
    setSelectAll(prev => !prev);
  };

  const handleSelectArchived = (newIsArchiveIncludedValue: boolean) => {
    if (!newIsArchiveIncludedValue) {
      // If archived projects were selected and the user toggles back to only unarchived projects,
      // filter the archived ones out of the selected array
      onChange(
        value.filter(project => !project.additionalProps),
        name
      );
    }
    setIsArchivedIncluded(prev => !prev);
  };

  return (
    <div className={styles.container}>
      <Multiselect
        id={id}
        name={name}
        value={value}
        placeholder={label}
        onChange={onChange}
        options={projectsSorted}
        isDisabled={isDisabled}
      />
      {hasSelectAllOption && (
        <Checkbox
          onChange={handleSelectAll}
          label={`Select all ${label.toLocaleLowerCase()}`}
          checked={selectAll}
          disabled={isDisabled || projectsSorted.length <= 0}
        />
      )}
      <Checkbox
        label="Include archived projects"
        onChange={() => handleSelectArchived(!isArchivedIncluded)}
        checked={isArchivedIncluded}
        disabled={isDisabled}
      />
    </div>
  );
};

export default ProjectStagesSelectFormField;
