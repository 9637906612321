import Icon from 'components/atoms/Icon';
import { ItemsCount } from 'components/ui/ItemsCount/ItemsCount';
import {
  SortOption,
  TechnicalSkillSortField,
  useTechnicalSkillSearchQuery,
} from 'generated/graphql';
import { useEffect, useMemo, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import NoSearchResultsTableContent from 'views/Projects/components/NoSearchResultsTableContent/NoSearchResultsTableContent';
import { getFiltersCount } from 'views/Projects/Projects.helpers';
import {
  Button,
  HeaderSortDirection,
  Search,
  Table,
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  DEFAULT_TABLE_PAGE_SIZE,
  Pagination,
  parseTableData,
  tableHeaders,
  TechnicalSkillFilters,
  TOrder,
} from '../TechnicalSkills.constants';
import TechnicalSkillsFilterModal from '../TechnicalSkillsFilterModal';
import styles from './styles.module.scss';

const TechnicalSkillsList = () => {
  const { handleMsgType } = useNotification();
  const [searchParams, setSearchParams] = useSearchParams();

  // search
  const [searchValue, setSearchValue] = useState(
    searchParams.get('search') || ''
  );
  const [order, setOrder] = useState<TOrder>({
    direction: (searchParams.get('direction') as SortOption) || SortOption.Asc,
    field:
      (searchParams.get('sortBy') as TechnicalSkillSortField) ||
      TechnicalSkillSortField.Name,
  });

  const [isFiltersModalOpen, setIsFiltersModalOpen] = useState(false);
  const [filters, setFilters] = useState<TechnicalSkillFilters>({
    skillCategoryNames:
      searchParams
        .get('filter')
        ?.split(',')
        .filter(category => category) || [],
  });

  // pagination
  const [currentTablePage, setCurrentTablePage] = useState<number>(
    Number(searchParams.get('page')) || 1
  );
  const [currentTablePageSize, setCurrentPageSize] = useState<number>(
    Number(searchParams.get('perPage')) || DEFAULT_TABLE_PAGE_SIZE
  );

  const { data, loading } = useTechnicalSkillSearchQuery({
    variables: {
      searchText: searchValue,
      skip: (currentTablePage - 1) * currentTablePageSize,
      take: currentTablePageSize,
      skillCategoryNames: filters.skillCategoryNames,
      sortOrder: order?.direction,
      sortField: order?.field,
    },
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message:
          error.message || 'Error occurred while getting technical skills',
      });
    },
  });

  const itemsTotal = data?.TechnicalSkillFreeTextSearchPaginated.total;
  const tableRows = useMemo(
    () =>
      data?.TechnicalSkillFreeTextSearchPaginated.technicalSkills.map(
        parseTableData
      ),
    [data?.TechnicalSkillFreeTextSearchPaginated.technicalSkills]
  );

  // Update URL when any parameter changes
  useEffect(() => {
    const params = {
      search: searchValue || '',
      filter: filters.skillCategoryNames.join(','),
      sortBy: order?.field || '',
      direction: order?.direction || '',
      page: currentTablePage.toString() || '',
      perPage: currentTablePageSize.toString() || '',
    };

    setSearchParams(params);
  }, [
    searchValue,
    filters,
    order,
    currentTablePage,
    currentTablePageSize,
    setSearchParams,
  ]);

  const handleSearch = (value: string) => {
    // Reset pagination on search change.
    setCurrentTablePage(1);
    setSearchValue(value);
  };

  const handleSearchClearBtnClick = () => {
    // Reset pagination on search change.
    setCurrentTablePage(1);
    handleSearch('');
  };

  const handlePaginationChange = ({
    page: newPage,
    pageSize: newPageSize,
  }: Pagination) => {
    currentTablePage !== newPage && setCurrentTablePage(newPage);
    currentTablePageSize !== newPageSize && setCurrentPageSize(newPageSize);
  };

  const handleSortChange = (
    field: string,
    tableSortDirection: HeaderSortDirection
  ) => {
    let direction;

    if (tableSortDirection === HeaderSortDirection.ASC) {
      direction = SortOption.Asc;
    }

    if (tableSortDirection === HeaderSortDirection.DESC) {
      direction = SortOption.Desc;
    }

    const newSortOrder = direction && {
      field: field as TechnicalSkillSortField,
      direction,
    };

    setOrder(newSortOrder);
  };

  const handleTableSort = useMemo(() => {
    let sortDirection = HeaderSortDirection.NONE;

    if (order?.direction === SortOption.Asc) {
      sortDirection = HeaderSortDirection.ASC;
    }

    if (order?.direction === SortOption.Desc) {
      sortDirection = HeaderSortDirection.DESC;
    }

    return {
      sortDirection,
      onClick: handleSortChange,
      sortedHeader: order?.field || '',
    };
  }, [order?.direction, order?.field]);

  const filterCount = useMemo(() => {
    return getFiltersCount(filters) || undefined;
  }, [filters]);

  const handleSubmitFilters = (formValues: TechnicalSkillFilters) => {
    setFilters(formValues);
  };

  return (
    <div className={styles.container} data-testid="technical-skills-list">
      <Table
        className={styles.table}
        headerData={tableHeaders}
        hasToolbar
        loading={loading}
        rowData={tableRows}
        hasPagination
        tableSearchDirty={Boolean(searchValue)}
        externalSort={handleTableSort}
        noSearchResultsText={<NoSearchResultsTableContent />}
        pagination={{
          currentPageSize: currentTablePageSize,
          onChange: handlePaginationChange,
          totalNumberOfItems: itemsTotal || 0,
          page: currentTablePage,
        }}
        toolbarConf={{
          componentListLeftStart: (
            <ItemsCount
              count={itemsTotal || 0}
              ariaLabelSuffix="technical skills in the table"
            />
          ),
          componentListLeft: (
            <div className={styles.toolbarContainer}>
              <form
                onSubmit={e => e.preventDefault()}
                className={styles.searchForm}
              >
                <Search
                  internalSearch={false}
                  onSearch={handleSearch}
                  inputValue={searchValue}
                  onClearBtnClick={handleSearchClearBtnClick}
                  ariaLabel="Search"
                  ariaDisabled={false}
                  value={{
                    value: '',
                    label: searchValue,
                  }}
                />
              </form>
              <Button
                variant="secondary"
                leftIcon={<Icon icon="filter" />}
                onClick={() => setIsFiltersModalOpen(true)}
                data-testid="technical-skills-list__filter-button"
                counter={filterCount}
              >
                Filter
              </Button>
            </div>
          ),
        }}
      />
      <TechnicalSkillsFilterModal
        isOpen={isFiltersModalOpen}
        onClose={() => setIsFiltersModalOpen(false)}
        onSubmit={handleSubmitFilters}
        filters={filters}
      />
    </div>
  );
};

export default TechnicalSkillsList;
