import { DEBOUNCE_TIME_DEFAULT } from 'constants/debounce';
import { useStackOneJobsFindManyPaginatedQuery } from 'generated/graphql';
import { useCompanyAts } from 'hooks/useCompanyAts';
import { debounce } from 'lodash';
import { useMemo, useRef, useState } from 'react';
import { Table } from '@spotted-zebra-uk/ui';
import {
  Select,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';
import { COLUMNS, parseRowData } from './AtsLinks.helpers';
import styles from './AtsLinks.module.scss';

const DEFAULT_PAGE_SIZE = 10;

export function AtsLinks() {
  const {
    selectOptions,
    loading,
    searchValue,
    setSearchValue,
    hasMoreItems,
    loadMore,
  } = useCompanyAts();
  const [selectedCompany, setSelectedCompany] = useState<TSelectOption>();
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: DEFAULT_PAGE_SIZE,
  });

  const [selectedRowsModel] = useState();

  const rowCountRef = useRef(0);

  const { data, loading: loadingTable } = useStackOneJobsFindManyPaginatedQuery(
    {
      variables: {
        companyId: Number(selectedCompany?.value),
        take: pagination.pageSize,
        skip: pagination.pageSize * pagination.page,
      },
      skip: !Boolean(selectedCompany?.value),
    }
  );

  const pageInfo = data?.stackOneJobsFindManyPaginated?.pageInfo;

  function handleInputChange(value: string) {
    if (!value && searchValue !== '') {
      setSearchValue('');
      return;
    }

    if (value) {
      setSearchValue(value);
      return;
    }
  }

  const debouncedInputChange = debounce(
    handleInputChange,
    DEBOUNCE_TIME_DEFAULT
  );

  const rows = useMemo(() => parseRowData(data), [data]);

  // The value rowCount becomes undefined during loading, it will reset the page to zero.
  // To avoid this issue, we can memoize the rowCount value to ensure it doesn't change during loading
  // More info: https://mui.com/x/react-data-grid/pagination/#index-based-pagination
  const rowCount = useMemo(() => {
    if (pageInfo?.itemsTotal !== undefined) {
      rowCountRef.current = pageInfo.itemsTotal;
    }

    return rowCountRef.current;
  }, [pageInfo?.itemsTotal]);

  return (
    <section className={styles.container} data-testid="ats-links-page">
      <h1 className={styles.heading}>ATS links</h1>

      <Select
        id="ats-links-company-selector"
        placeholder="Company"
        value={selectedCompany}
        options={selectOptions}
        onChange={value => setSelectedCompany(value)}
        onLoadMore={loadMore}
        onInputChange={debouncedInputChange}
        loadMore={hasMoreItems}
        loadMoreButtonText="Load more"
        isLoading={loading}
        className={styles.companyDropdown}
      />

      <Tabs className={styles.tabs}>
        <TabList>
          <Tab counter={rowCount}>Unlinked requisitions</Tab>
          <Tab counter={0}>Linked requisitions</Tab>
        </TabList>

        <TabPanels>
          <TabPanel>
            <Table
              rows={rows}
              columns={COLUMNS}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                columns: ['status'],
              }}
              loading={loadingTable}
              rowCount={rowCount}
              paginationMode="server"
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              checkboxSelection
              rowSelectionModel={selectedRowsModel}
              onRowSelectionModelChange={selectOptions =>
                // more on later implementation
                console.log(selectOptions)
              }
              keepNonExistentRowsSelected
              emptyTableContent={<EmptyTableContent />}
              hasToolbar={false}
              disableColumnFilter
            />
          </TabPanel>
          <TabPanel>
            <Table
              rows={[]}
              columns={COLUMNS}
              autosizeOnMount
              autosizeOptions={{
                expand: true,
                columns: COLUMNS.map(c => c.field),
              }}
              // loading={loadingTable}
              rowCount={rowCount}
              paginationMode="server"
              paginationModel={pagination}
              onPaginationModelChange={setPagination}
              checkboxSelection
              rowSelectionModel={selectedRowsModel}
              onRowSelectionModelChange={selectOptions =>
                // more on later implementation
                console.log(selectOptions)
              }
              keepNonExistentRowsSelected
              emptyTableContent={<EmptyTableContent />}
            />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </section>
  );
}

function EmptyTableContent() {
  return (
    <div className={styles.emptyTableContent}>
      Select a company to begin.
      <br />
      <br />
      This will allow you to start viewing and sorting the requsitions tied to
      the company’s ATS.
    </div>
  );
}
