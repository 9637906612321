import ProjectStagesSelectFormField from 'components/molecules/MultiselectFormField/ProjectStagesMultiSelectField/ProjectStagesMultiSelectField';
import {
  useAdminResultsCsvFileExportMutation,
  useCompaniesQuery,
} from 'generated/graphql';
import { FC, useMemo, useState } from 'react';
import {
  Button,
  CheckboxGroup,
  CheckboxGroupItem,
  InlineNotification,
  NotificationType,
  SelectFormField,
  TMultiselectOption,
  TNotification,
  TSelectOption,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import {
  checkboxOptions,
  emptyOption,
  ExportFormType,
  exportLevelOptions,
  formOptions,
  initialFormValues,
  scoreTypeOptions,
} from './StageCSV.constants';
import styles from './StageCSV.module.scss';

const StageCSV: FC = () => {
  const { handleMsgType } = useNotification();
  const [selectedCompany, setSelectedCompany] =
    useState<TSelectOption>(emptyOption);
  const [exportFormValues, setExportFormValues] =
    useState<ExportFormType>(initialFormValues);
  const [selectedFormsCheckboxes, setSelectedFormsCheckboxes] = useState<
    string[]
  >([]);

  const { data: companiesData } = useCompaniesQuery({
    onError: error => {
      handleMsgType({
        type: TNotification.error,
        message: `${error.message}`,
      });
    },
  });

  const [generateScheduledCsvExport, { loading: loadingGenerateCSV }] =
    useAdminResultsCsvFileExportMutation({
      onError: error => {
        handleMsgType({
          type: TNotification.error,
          title: 'Something went wrong',
          message: `${error.message}`,
        });
      },
      onCompleted: () => {
        handleMsgType({
          type: TNotification.success,
          title: `Successfully requested export!`,
        });
      },
    });

  const companySelectOptions: TSelectOption[] = useMemo(
    () =>
      companiesData?.Companies?.map(company => ({
        label: `${company.name} (${company.id})`,
        value: company.id.toString(),
      })).sort((a, b) => (a.label < b.label ? -1 : 1)) || [],
    [companiesData?.Companies]
  );

  const handleSelectCompany = (newValue: TSelectOption) => {
    setSelectedCompany(newValue);
    setExportFormValues(initialFormValues);
  };

  const handleSelectStages = (
    newValue: TMultiselectOption<string>[],
    _: string
  ) => {
    setExportFormValues(prev => ({ ...prev, stageIds: newValue }));
  };

  const handleExportValue = (
    key: keyof ExportFormType,
    newValue: TSelectOption
  ) => {
    setExportFormValues(prev => ({ ...prev, [key]: newValue }));
  };

  const handleCheckboxesChange = (value: string[]) => {
    setSelectedFormsCheckboxes(value);
  };

  const handleResetForm = () => {
    setExportFormValues(initialFormValues);
    setSelectedCompany(emptyOption);
    setSelectedFormsCheckboxes([]);
  };

  const handleSubmit = () => {
    generateScheduledCsvExport({
      variables: {
        exportLevel: exportFormValues.exportLevel.value,
        scoreType: exportFormValues.scoreType.value,
        includeDeiData: selectedFormsCheckboxes.includes(formOptions.dei),
        includeFormsData: selectedFormsCheckboxes.includes(
          formOptions.customForms
        ),
        stageIds: exportFormValues.stageIds.map(stage => Number(stage.value)),
      },
    });
  };

  const isSubmitButtonDisabled = () => {
    return !selectedCompany.value || exportFormValues.stageIds.length === 0;
  };

  return (
    <main className={styles.container}>
      <h1 className={styles.title}>Candidate Results Export</h1>
      <InlineNotification
        title={
          'You will receive an email to download the export once it is generated.'
        }
        notificationType={NotificationType.INFORMATION}
      />
      <section className={styles.formContainer}>
        <div data-testid="candidate-results-export__company-select">
          <SelectFormField
            id="company"
            name="company"
            placeholder="Company"
            label="Company"
            onChange={handleSelectCompany}
            value={selectedCompany}
            options={companySelectOptions}
          />
        </div>
        <div data-testid="candidate-results-export__project-select">
          <ProjectStagesSelectFormField
            id="stageIds"
            name="stageIds"
            label="Projects"
            value={exportFormValues.stageIds}
            onChange={handleSelectStages}
            companyId={Number(selectedCompany?.value)}
            isDisabled={!selectedCompany.value}
            hasSelectAllOption={true}
          />
        </div>

        <div data-testid="candidate-results-export__export-level-select">
          <SelectFormField
            id="exportLevel"
            name="exportLevel"
            placeholder="Export level"
            label="Export level"
            onChange={(newValue: TSelectOption) =>
              handleExportValue('exportLevel', newValue)
            }
            value={exportFormValues.exportLevel}
            options={exportLevelOptions}
          />
        </div>
        <div data-testid="candidate-results-export__score-type-select">
          <SelectFormField
            id="scoreType"
            name="scoreType"
            placeholder="Score type"
            label="Score type"
            onChange={(newValue: TSelectOption) =>
              handleExportValue('scoreType', newValue)
            }
            value={exportFormValues.scoreType}
            options={scoreTypeOptions}
          />
        </div>

        <CheckboxGroup
          id="candidate-results-export-checkbox-group"
          value={selectedFormsCheckboxes}
          onChange={handleCheckboxesChange}
        >
          {checkboxOptions.map(({ label, value }) => (
            <div
              data-testid={`candidate-results-export__checkbox-button-${value}`}
              key={value}
            >
              <CheckboxGroupItem label={label} id={value} />
            </div>
          ))}
        </CheckboxGroup>
      </section>
      <div className={styles.buttonsContainer}>
        <Button
          size="large"
          variant="primary"
          className={styles.submitButton}
          disabled={isSubmitButtonDisabled()}
          data-testid="candidate-results-export__submit-button"
          type="submit"
          onClick={handleSubmit}
          loading={loadingGenerateCSV}
        >
          Generate export
        </Button>
        <Button
          size="large"
          variant="tertiary"
          data-testid="candidate-results-export__reset-button"
          type="reset"
          onClick={handleResetForm}
          disabled={loadingGenerateCSV}
        >
          Reset
        </Button>
      </div>
    </main>
  );
};

export default StageCSV;
