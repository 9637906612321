import Button from 'components/atoms/Button/Button';
import CircularLoader from 'components/molecules/CircularLoader/CircularLoader';
import ProjectJobRoleSelectFormField from 'components/molecules/SelectFormField/ProjectJobRoleSelectFormField/ProjectJobRoleSelectFormField';
import {
  ReportRenderType,
  ReportSubType,
  ReportType,
  StageCandidateStatus,
  useProjectJobRoleFindManyQuery,
  useReportRenderFindManyLazyQuery,
} from 'generated/graphql';
import { usePollingReportRenderQuery } from 'helpers/reportRender';
import { TSelectFormFieldValue } from 'interfaces/forms/form';
import { FC, useEffect, useRef, useState } from 'react';
import ReportSubTypeFormField from 'views/ModalView/ReportsModal/ReportSubTypeFormField/ReportSubTypeFormField';
import {
  TNotification,
  useNotification,
} from '@spotted-zebra-uk/ui-components';
import useUserTableStyle from '../../../../../UserMain/UserTable/UserTableStyle';
import { IExistingReport } from '../../UserResets.interface';

const ExistingReport: FC<IExistingReport> = ({
  stageStatus,
  projectId,
  stageCandidateId,
  onReportLoaded,
  onSetReportType,
  activeReportType,
}) => {
  const { handleMsgType } = useNotification();
  const reportRenderSubIdRef = useRef<string | null>('existing');

  const classes = useUserTableStyle();
  const [reportSubType, setReportSubType] = useState<TSelectFormFieldValue>('');
  const [selectedJobRoleId, setSelectedJobRoleId] =
    useState<TSelectFormFieldValue>('');

  const { data: projectJobRoles } = useProjectJobRoleFindManyQuery({
    variables: {
      id: projectId,
    },
    onCompleted: data => {
      if (data?.ProjectJobRoleFindMany) {
        setSelectedJobRoleId(data?.ProjectJobRoleFindMany[0]?.id);
      }
    },
  });

  const { startPolling, isPolling, reportRenderQueryResponse } =
    usePollingReportRenderQuery(reportRenderSubIdRef.current, true);

  const [reportRenderFindManyQuery] = useReportRenderFindManyLazyQuery({
    onError: error => {
      handleMsgType({ type: TNotification.error, message: error?.message });
    },
    fetchPolicy: 'cache-and-network',
    onCompleted: data => {
      if (data?.report) {
        reportRenderSubIdRef.current = data.report[0].subId;
        startPolling();
      }
    },
    variables: {
      subType: reportSubType as ReportSubType,
      dataSourceId: Number(stageCandidateId),
      reportRenderType: ReportRenderType.Pdf,
      projectJobRoleId: Number(selectedJobRoleId),
    },
  });

  useEffect(() => {
    if (reportRenderQueryResponse?.data && activeReportType === 'existing') {
      onReportLoaded(reportRenderQueryResponse.data);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reportRenderQueryResponse.data]);

  const handleSelectedJobRoleId = (value: TSelectFormFieldValue) => {
    setSelectedJobRoleId(value);
  };

  const handleOpenReport = () => {
    onSetReportType('existing');
    reportRenderFindManyQuery();
  };

  const handleReportSubType = (value: TSelectFormFieldValue) => {
    setReportSubType(value);
  };

  const isProjectMultimatch =
    (projectJobRoles?.ProjectJobRoleFindMany.length ?? 0) > 1;

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        backgroundColor: '#f2f2f2',
        padding: '20px',
      }}
    >
      <p>Open existing report</p>
      <div
        style={{
          display: 'flex',
          width: isProjectMultimatch ? 700 : 350,
          gap: 10,
        }}
      >
        {isProjectMultimatch && (
          <ProjectJobRoleSelectFormField
            id="projectJobRoleSelect"
            name="projectJobRole"
            label="Job role"
            projectJobRoles={projectJobRoles?.ProjectJobRoleFindMany ?? []}
            onChange={handleSelectedJobRoleId}
          />
        )}
        <ReportSubTypeFormField
          id="subTypeFormField"
          name="subType"
          label="Sub type"
          value={reportSubType}
          onChange={handleReportSubType}
          selectedReportType={ReportType.StageResults}
          isDisabled={stageStatus !== StageCandidateStatus.Completed}
        />
        <div style={{ marginLeft: 10 }}>
          {isPolling && reportRenderQueryResponse?.data?.ReportRender ? (
            <CircularLoader customStyle={classes.loaderWrapper} />
          ) : (
            <Button isDisabled={!reportSubType} onClick={handleOpenReport}>
              Existing Report
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ExistingReport;
