import { useSkillCategoryFindManyQuery } from 'generated/graphql';
import { useMemo, useState } from 'react';
import { getFiltersCount } from 'views/Projects/Projects.helpers';
import {
  Button,
  Modal,
  Multiselect,
  TMultiselectOption,
} from '@spotted-zebra-uk/ui-components';
import styles from './styles.module.scss';
import {
  getSelectOption,
  TechnicalSkillFilters,
} from './TechnicalSkills.constants';

type FiltersModalProps = {
  filters: TechnicalSkillFilters;
  isOpen: boolean;
  onClose: () => void;
  onSubmit: (values: TechnicalSkillFilters) => void;
};

const formatFilters = (selectedCategories: TMultiselectOption[]) => {
  return {
    skillCategoryNames: selectedCategories.map(
      category => category.value as string
    ),
  };
};

const TechnicalSkillsFilterModal = ({
  isOpen,
  onClose,
  filters,
  onSubmit,
}: FiltersModalProps) => {
  const [selectedCategories, setSelectedCategories] = useState<
    TMultiselectOption[]
  >(filters.skillCategoryNames.map(getSelectOption));

  const { data: allCategoriesResponse } = useSkillCategoryFindManyQuery();
  const selectOptions: TMultiselectOption[] = useMemo(
    () =>
      allCategoriesResponse?.SkillCategoryFindMany.map(category => ({
        label: category.name,
        value: category.name,
      })) || [],
    [allCategoriesResponse]
  );

  const handleSelect = (newValue: TMultiselectOption[]) => {
    setSelectedCategories(newValue);
  };

  const handleSubmit = () => {
    onSubmit(formatFilters(selectedCategories));
    onClose();
  };

  const handleReset = () => {
    setSelectedCategories([]);
    onSubmit({ skillCategoryNames: [] });
    onClose();
  };

  const filterCount = useMemo(() => {
    return getFiltersCount(formatFilters(selectedCategories)) || undefined;
  }, [selectedCategories]);

  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      header="Filter"
      data-testid="technical-skills-filter-modal"
      className={styles.filterModal}
    >
      <Multiselect
        id="technical-skills-filter-modal-skill-category-names"
        placeholder={'Skill Category'}
        options={selectOptions}
        onChange={handleSelect}
        value={selectedCategories}
      />
      <div className={styles.buttons}>
        <Button variant="tertiary" onClick={handleReset}>
          Reset filter
        </Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button variant="primary" onClick={handleSubmit} counter={filterCount}>
          Apply
        </Button>
      </div>
    </Modal>
  );
};

export default TechnicalSkillsFilterModal;
