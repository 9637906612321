import {
  AdminResultsExportLevel,
  AdminResultsExportScoreType,
} from 'generated/graphql';
import { lowerCase, startCase } from 'lodash';
import {
  TMultiselectOption,
  TSelectOption,
} from '@spotted-zebra-uk/ui-components';

export const exportLevelOptions = [
  {
    label: 'Summary of scores',
    value: AdminResultsExportLevel.SummaryOfScores,
    labelNote: 'Overall and Right for category scores',
  },
  {
    label: 'Summary and score details',
    value: AdminResultsExportLevel.SummaryAndScoreDetails,
    labelNote: 'Overall, Right for category & detailed scores per soft skill',
  },
  {
    label: 'All data',
    value: AdminResultsExportLevel.AllData,
    labelNote: 'All available details associated with all candidates',
  },
];

const formatLabel = (input: string) => {
  return startCase(lowerCase(input));
};

export const scoreTypeOptions = [
  {
    label: formatLabel(AdminResultsExportScoreType.Percentile),
    value: AdminResultsExportScoreType.Percentile,
  },
  {
    label: formatLabel(AdminResultsExportScoreType.Grade),
    value: AdminResultsExportScoreType.Grade,
  },
  {
    label: formatLabel(AdminResultsExportScoreType.StenScore),
    value: AdminResultsExportScoreType.StenScore,
  },
  {
    label: formatLabel(AdminResultsExportScoreType.ZScore),
    value: AdminResultsExportScoreType.ZScore,
  },
];

export const formOptions = {
  dei: 'deiForm',
  customForms: 'customForms',
};

export const checkboxOptions = [
  { label: 'Include custom forms data', value: formOptions.customForms },
  {
    label: 'Include diversity, equity & inclusion data',
    value: formOptions.dei,
  },
];

export type ExportFormType = {
  stageIds: TMultiselectOption[];
  exportLevel: TSelectOption<AdminResultsExportLevel>;
  scoreType: TSelectOption<AdminResultsExportScoreType>;
  includedDeiData: boolean;
};

export const initialFormValues = {
  stageIds: [],
  exportLevel: exportLevelOptions[0],
  scoreType: scoreTypeOptions[0],
  includedDeiData: false,
  includeCustomFormsData: false,
};

export const emptyOption = {
  label: '',
  value: '',
};
